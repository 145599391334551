const API_BASE = 'https://chatham-meter-swap.carlsapp.com/api';
const STORAGE_KEY = 'MeterData';
const WINDOW_TITLE = 'Chatham Meter Swap';
const APP_VERSION = '2.3';
const DEFAULT_IMPORT_FILE_FORMAT = 'Fixed Width';

// To use this file in a CommonJS environment replace export with module.exports =.
export {
    API_BASE, STORAGE_KEY, WINDOW_TITLE, APP_VERSION,
    DEFAULT_IMPORT_FILE_FORMAT,
};
